import { Button } from '@/components/Button';
import { ALL_RECIPES_URL } from '@/constants';
import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter();

  const handleGoToRecipesClick = () => {
    router.push(ALL_RECIPES_URL);
  };

  return (
    <>
      <Head>
        <title>404 - Siden blev ikke fundet</title>
      </Head>
      <div className="mt-10 md:mt-32 w-screen bg-gray-100 flex items-center justify-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="max-w-md text-center">
            <p className="text-2xl text-gray-400 font-bold mb-2">404</p>
            <h1 className="text-2xl md:text-5xl text-primary font-bold mb-6">
              Øv, vi kunne ikke finde lige præcis den der opskrift
            </h1>
            <p className="font-bold text-lg mb-8">
              Heldigivs er der virkelig mange andre lækre opskrifter her i Klub
              Vollmer{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </p>

            <Button large onClick={handleGoToRecipesClick}>
              Se alle opskrifterne
            </Button>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
